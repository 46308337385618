import { ADMIN_ACTIONS } from 'src/helper/actions'

const initialState = []

export const adminSlice = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADMIN_ACTIONS.SET_ADMIN:
      return {
        ...state,
        ...payload.data,
      }
    default:
      return state
  }
}
