import React, { useState, useEffect, Suspense } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { ToastContainer } from 'react-toastify'
import ProtectedRoute from './protected/protectedRoute'

import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'react-toastify/dist/ReactToastify.css'

import 'primereact/resources/primereact.min.css'
import './assets/css/style.css'
import Preloader from './components/Preeloader'

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  return (
    <>
      <BrowserRouter basename={`/`}>
        <ToastContainer limit={3} />
        <Suspense fallback={<Preloader />}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="*" name="Home" element={<ProtectedRoute cmp={DefaultLayout} />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  )
}

export default App
