import React from 'react'

const Preloader = () => {
  return (
    <section id="preloader">
      <div className="container pb-30">
        <div className="row">
          <div className="col-md-2">
            <div className="preloader-floating-circles">
              <div className="f_circleG" id="frotateG_01"></div>
              <div className="f_circleG" id="frotateG_02"></div>
              <div className="f_circleG" id="frotateG_03"></div>
              <div className="f_circleG" id="frotateG_04"></div>
              <div className="f_circleG" id="frotateG_05"></div>
              <div className="f_circleG" id="frotateG_06"></div>
              <div className="f_circleG" id="frotateG_07"></div>
              <div className="f_circleG" id="frotateG_08"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Preloader
