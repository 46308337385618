import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

export default function ProtectedRoute(props) {
  const Component = props.cmp
  const navigate = useNavigate()
  const admin_info = localStorage.getItem('admin_info')
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (!admin_info || !token) {
      navigate('/')
    }
  }, [])

  return (
    <>
      <Component />
    </>
  )
}

// Add prop validation
ProtectedRoute.propTypes = {
  cmp: PropTypes.elementType.isRequired,
}
