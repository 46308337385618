import { createStore, combineReducers } from 'redux'
import { adminSlice } from './reducers/adminSlice'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  mainState: changeState,
  adminState: adminSlice,
})

const store = createStore(rootReducer)
export default store
